var myCharts = [];

function resetCanvas(canvasNames) {
  canvasNames.forEach(clearCanvas);
}

function clearCanvas(canvasId) {
  var canvas = document.getElementById(canvasId); // this is my <canvas> element
  if (canvas != null) {
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    const chartToDestroy = myCharts.find(
      (chart) => chart.canvas != null && chart.canvas.id == canvasId
    );
    if (chartToDestroy != undefined) {
      chartToDestroy.destroy();
      myCharts = myCharts.filter(
        (chart) => chart.canvas != null && chart.canvas.id != canvasId
      );
    }
  }
}

function buildOrUpdateChart(
  scenarioNames,
  canvasId,
  fullData,
  number,
  xLabel,
  onClickCallback = null
) {
  var ctx = document.getElementById(canvasId);
  if (ctx != null) {
    ctx.parentElement.style.height = number * 10 + "px";
  }
  const myChart = myCharts.find(
    (chart) => chart.canvas != null && chart.canvas.id == canvasId
  );

  if (myChart != undefined) {
    myChart.data.datasets = fullData;
    myChart.data.labels = scenarioNames;
    myChart.options.scales.xAxes[0].scaleLabel.labelString = xLabel;
    myChart.options.onClick = onClickCallback;
    myChart.options.onHover = onClickCallback
      ? (evt, activeElements) => {
          if (activeElements.length) {
            document.getElementById(canvasId).style.cursor = "pointer";
          }
        }
      : null;
    myChart.update();
  } else {
    buildChart(
      scenarioNames,
      canvasId,
      fullData,
      number,
      xLabel,
      onClickCallback
    );
  }
}

function buildChart(
  scenarioNames,
  chart,
  fullData,
  number,
  xLabel,
  onClickCallback = null
) {
  var ctx = document.getElementById(chart);
  if (ctx != null) {
    ctx.parentElement.style.height = number * 10 + "px";
    myChart = new Chart(ctx, {
      type: "horizontalBar",
      data: {
        labels: scenarioNames,

        datasets: fullData,
      },
      options: {
        onClick: onClickCallback,
        onHover: onClickCallback
          ? (evt, activeElements) => {
              if (activeElements.length) {
                document.getElementById(chart).style.cursor = "pointer";
              }
            }
          : null,
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          mode: "nearest",
          callbacks: {
            title: function (tooltipItems, data) {
              return data.datasets[tooltipItems[0].datasetIndex].label || "";
            },
            label: function (tooltipItem, data) {
              var total = 0;
              for (var i = 0; i < data.datasets.length; i++)
                total += parseFloat(data.datasets[i].data[tooltipItem.index]);
              const floatRelativeValue = parseFloat(
                (tooltipItem.value * 100) / total
              ).toFixed(0);
              const floatValue = parseFloat(tooltipItem.value);
              const formattedValue =
                floatValue < 0.1
                  ? floatValue.toExponential(1)
                  : floatValue.toFixed(1);
              return (
                " " + formattedValue + " (" + floatRelativeValue + "%)" || ""
              );
            },
          },
          custom: function (tooltip) {
            if (!tooltip.opacity) {
              document.getElementById(chart).style.cursor = "default";
              return;
            }
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: xLabel,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              stacked: true,
              gridLines: {
                display: false,
              },
              height: 20,
              dataset: {
                barPercentage: 0.6,
              },
            },
          ],
        },
        legend: {
          onHover: function (event, legendItem) {
            document.getElementById(chart).style.cursor = "pointer";
          },
          display: true,
          position: "bottom",
          align: "start",
          fullWidth: true,
          labels: {
            font: "Muli sans-serif",
          },
          legendCallback: {
            text: function (legendItem, data) {
              return (
                data.datasets[legendItem.datasetIndex].resultCategory || ""
              );
            },
          },
        },
        pointLabelFontFamily: "Muli sans-serif",
        scaleFontFamily: "Muli sans-serif",
      },
    });
    myCharts.push(myChart);
  }
}
